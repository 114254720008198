import * as React from 'react';
import { graphql } from 'gatsby';
import pose from 'react-pose';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import SEO from '../components/seo';
import { FlexContent } from '../components/grid';
import { H1, Header, Hanger, StyledArticle, StyledInfo, MarkdownStyler, Layout, SubTitle, } from '../components/template.common';
const TRANSITION = {
    ease: 'easeOut',
};
const PoseHeader = pose.div({
    enter: {
        delay: 150,
        opacity: 1,
        transition: TRANSITION,
    },
    exit: {
        opacity: 0,
        transition: TRANSITION,
    },
});
const PoseContent = pose.div({
    enter: {
        delay: 250,
        opacity: 1,
        y: 0,
        transition: TRANSITION,
    },
    exit: {
        opacity: 0,
        y: -5,
        transition: TRANSITION,
    },
});
const Entry = ({ featureImage, title, html, date, tags }) => {
    return (<FlexContent>
      <PoseContent>
        <StyledArticle>
          {featureImage && <GatsbyImage style={{
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: 'auto',
    }} fadeIn={true} fluid={featureImage.childImageSharp.fluid}/>}
          <StyledInfo>
            <h1>{title}</h1>
            <MarkdownStyler dangerouslySetInnerHTML={{
        __html: html,
    }}/>
            <Hanger>
              <p>
                <strong>{date}</strong>
              </p>
              {tags && tags.length > 0 && <p>{tags.join(' ')}</p>}
            </Hanger>
          </StyledInfo>
        </StyledArticle>
      </PoseContent>
    </FlexContent>);
};
const CharityIndex = ({ data, pageContext }) => {
    const charityContents = data.charityContents.nodes;
    const { title, subTitle, description } = data.charityIndex.frontmatter;
    const { lang } = pageContext;
    return (<Layout>
      <SEO {...{
        title,
        lang,
        description,
    }}/>
      <PoseHeader>
        <Header>
          <FlexContent>
            <H1 dangerouslySetInnerHTML={{
        __html: title,
    }}/>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
          </FlexContent>
        </Header>
      </PoseHeader>
      <ul>
        {charityContents.map(({ fields: { featureImage }, frontmatter: { title, date, tags }, html, }) => (<li key={title}>
              <Entry {...{
        title,
        html,
        date,
        tags,
        featureImage,
    }}/>
            </li>))}
      </ul>
    </Layout>);
};
export default CharityIndex;
export const pageQuery = graphql `
  query CharityIndex($lang: String!) {
    charityIndex: markdownRemark(
      frontmatter: { type: { eq: "page/charity" }, lang: { eq: $lang } }
    ) {
      frontmatter {
        title
        subTitle
      }
    }
    charityContents: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "content/charity" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        html
        fields {
          featureImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        frontmatter {
          title
          featureImage
          date(formatString: "YYYY.MM.DD")
          tags
        }
      }
    }
  }
`;
